const initialDataSize = 7;

const DATA = [
  {
    date: "2/17",
    value: [115, 116],
  },
  {
    date: "3/1",
    value: [116, 115],
  },
  {
    date: "3/10",
    value: [115, 116],
  },
  {
    date: "3/22",
    value: [116, 119],
  },
  {
    date: "3/31",
    value: [119, 122],
  },
  {
    date: "4/11",
    value: [122, 124],
  },
  {
    date: "4/20",
    value: [124, 129],
  },
  {
    date: "5/2",
    value: [129, 130],
  },
  {
    date: "5/16",
    value: [130, 129],
  },
  {
    date: "5/25",
    value: [129, 127],
  },
  {
    date: "6/3",
    value: [127, 130],
  },
  {
    date: "6/14",
    value: [130, 134],
  },
  {
    date: "6/23",
    value: [134, 136],
  },
  {
    date: "7/4",
    value: [136, 135],
  },
  {
    date: "7/13",
    value: [135, 137],
  },
  {
    date: "7/25",
    value: [137, 136],
  },
  {
    date: "8/3",
    value: [136, 133],
  },
  {
    date: "8/15",
    value: [133, 133],
  },
  {
    date: "8/24",
    value: [133, 137],
  },
  {
    date: "9/2",
    value: [137, 140],
  },
  {
    date: "9/13",
    value: [140, 143],
  },
  {
    date: "9/26",
    value: [143, 144],
  },
  {
    date: "10/5",
    value: [144, 144],
  },
  {
    date: "10/17",
    value: [144, 149],
  },
  {
    date: "10/26",
    value: [149, 148],
  },
  {
    date: "11/7",
    value: [148, 147],
  },
  {
    date: "11/16",
    value: [147, 139],
  },
  {
    date: "11/28",
    value: [139, 139],
  },
  {
    date: "12/7",
    value: [139, 137],
  },
  {
    date: "12/16",
    value: [137, 138],
  },
  {
    date: "12/27",
    value: [138, 133],
  },
  {
    date: "1/10",
    value: [133, 132],
  },
  {
    date: "1/19",
    value: [132, 129],
  },
];

const initialValue = DATA.slice(0, initialDataSize).map((item) => {
  return item.value;
});

const initialDate = DATA.slice(0, initialDataSize).map((item) => {
  return item.date;
});

export const INITIAL_VALUE_DATA = [
  ...initialValue
];

export const INITIAL_DATE_DATA = [
  ...initialDate
];

export const NEXT_VALUE_DATA = DATA.slice(initialDataSize).map((item) => {
  return item.value;
});

export const NEXT_DATE_DATA = DATA.slice(initialDataSize).map((item) => {
  return item.date;
});
