import classNames from "classnames";
import Button from "../../common/Button";
import styles from "./Result.module.css";

const Result = (props) => {
  const { result, sumResult, tradeDate, onClick } = props;

  return (
    <div className={styles["bg-black"]}>
      <div className={styles["bg-result"]} id="fx-game-result">
        <img
          src="https://www.fxschool.or.jp/wp-content/uploads/fx-game/media/result-left.1a5bac8146deff231202.png"
          alt=""
          className={classNames(
            styles["result-img"],
            styles["result-img-left"]
          )}
        />
        <img
          src="https://www.fxschool.or.jp/wp-content/uploads/fx-game/media/result-right.c1a97208deed25e28d79.png"
          alt=""
          className={classNames(
            styles["result-img"],
            styles["result-img-right"]
          )}
        />
        <div className={styles["result-text"]}>
          <div>
            <p className={styles["medium"]}>結果</p>
            <p className={styles["result-text-price"]}>
              {Math.round(result / 10000)}万{Math.abs(result % 10000)}
              円の利益
            </p>
            <p className={styles["medium"]}>が出ました</p>
          </div>
          <div className={styles["result-text-bottom"]}>
            <p className={styles["small"]}>
              取引の期間：{tradeDate.buy} - {tradeDate.sell}
            </p>
            <p className={styles["small"]}>
              合計の損得：{Math.round(sumResult / 10000)}万
              {Math.abs(sumResult % 10000)}万円
            </p>
          </div>
        </div>
        <div className={styles["result-button__outer"]}>
          <Button
            id="fx-game-replay-button"
            className={styles["result-button__again"]}
            onClick={() => onClick()}
            label="もう一度遊ぶ"
          />
          <a
            href="https://lin.ee/bYQnE3s"
            target="_blank"
            rel="noreferrer"
            id="fx-game-line-link"
            style={{ width: "100%" }}
          >
            <Button
              id="fx-game-line-button"
              className={styles["result-button__link"]}
              label="実際にお金を増やしたい"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Result;
