import styles from "./Button.module.css";
import classNames from "classnames";

const Button = (props) => {
  const {
    buttonRef, id,
    label,
    onClick = () => {},
    bgColor,
    className,
    style,
  } = props;
  return (
    <button
      ref={buttonRef}
      id={id}
      className={classNames(styles["button"], className)}
      style={{ backgroundColor: bgColor, ...style }}
      onClick={() => onClick()}
    >
      {label}
    </button>
  );
};

export default Button;
