import classNames from "classnames";
import styles from "./BlackBg.module.css";

const Initial = ({ setSceneStatue, moneyBoxRef }) => {
  const start = () => {
    setSceneStatue(2);
    moneyBoxRef.style.zIndex = 2;
  };

  return (
    <img
      id="fx-game-start-img"
      src="https://www.fxschool.or.jp/wp-content/uploads/fx-game/media/top.avif"
      alt="スタート画面"
      width="100%"
      height="100%"
      onClick={start}
    />
  );
};

const FirstHint = () => {
  return (
    <>
      <div className={classNames(styles["bg-black__bubble"])}>
        <p>
          これが今の値段
          <br />
          低い時に買おう
        </p>
      </div>
      <span className={styles["click"]}>タップ</span>
    </>
  );
};

const BuyHint = () => {
  return (
    <>
      <div className={classNames(styles["bg-black__bubble"])}>
        <span>タケルベーシックルール</span>
        <p>
          今が買う
          <br />
          チャンス!!
        </p>
      </div>
      <span className={styles["click"]}>買わずに続ける</span>
    </>
  );
};

const SellHint = () => {
  return (
    <>
      <div className={classNames(styles["bg-black__bubble"])}>
        <span>タケルベーシックルール</span>
        <p>
          今が売る
          <br />
          チャンス!!
        </p>
      </div>
      <span className={styles["click"]}>売らずに続ける</span>
    </>
  );
};

const BlackBg = (props) => {
  const { sceneStatue, setSceneStatue, moneyBoxRef } = props;
  return (
    <>
      {sceneStatue && (
        <div
          className={styles[sceneStatue !== 1 ? "bg-black" : "bg-black__start"]}
        >
          {sceneStatue === 1 && (
            <Initial
              setSceneStatue={setSceneStatue}
              moneyBoxRef={moneyBoxRef?.current}
            />
          )}
          {sceneStatue === 2 && <FirstHint />}
          {sceneStatue === 3 && <BuyHint />}
          {sceneStatue === 4 && <SellHint />}
        </div>
      )}
    </>
  );
};

export default BlackBg;
