import { Chart as ChartJS, registerables } from "chart.js";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";

import {
  INITIAL_DATE_DATA,
  INITIAL_VALUE_DATA,
  NEXT_DATE_DATA,
  NEXT_VALUE_DATA,
} from "./constants/data";
import useWindowWidth from "./hooks/useWindowWidth";
import Button from "./components/common/Button";
import BlackBg from "./components/screen/BlackBg";
import Result from "./components/screen/Result";
import styles from "./App.module.css";

ChartJS.register(AnnotationPlugin, ...registerables);

const barColor = (data) => {
  return data.map((item) => {
    const [min, max] = item;
    if (min < max) {
      return "#ffcdcd";
    } else {
      return "#c1e2ff";
    }
  });
};

let options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      position: "right",
      min: 110,
      max: 150,
      ticks: {
        font: {
          size: 14,
        },
      },
    },
    x: {
      ticks: {
        font: {
          size: 14,
        },
      },
    },
  },
};

// 初期データ
const data = {
  labels: [...INITIAL_DATE_DATA, ""],
  datasets: [
    {
      data: [...INITIAL_VALUE_DATA],
      backgroundColor: barColor(INITIAL_VALUE_DATA.slice(0, 7)),
    },
  ],
};

// TODO:配列を扱いやすくする
let nextValueData = [...NEXT_VALUE_DATA];
let nextDateData = [...NEXT_DATE_DATA];

const App = () => {
  const [time, setTime] = useState(null);
  const [buyTime, setBuyTime] = useState(null);

  const [sceneStatue, setSceneStatue] = useState(1); // 1:開始前 2:今の値段 3:買うタイミング 4:売るタイミング 5:結果画面 null:ゲーム画面
  const initialMoney = INITIAL_VALUE_DATA[6][1];
  const [nowMoney, setNowMoney] = useState(initialMoney);
  const [boughtMoney, setBoughtMoney] = useState(0);
  const [tradeDate, setTradeDate] = useState({ buy: null, sell: null });
  const [result, setResult] = useState(0);
  const [sumResult, setSumResult] = useState(0);

  const chartRef = useRef(null);
  const currentRef = useRef(true);

  const moneyBoxRef = useRef(null);
  const buyButtonRef = useRef(null);
  const sellButtonRef = useRef(null);

  const width = useWindowWidth();
  if (width < 800) {
    chartRef.current.options.scales.x.ticks.font.size = 8;
    chartRef.current.options.scales.y.ticks.font.size = 8;
    chartRef.current.update();
  }

  useEffect(() => {
    // 売るタイミングのヒント
    if (currentRef.current && boughtMoney && boughtMoney + 5 <= nowMoney) {
      clearInterval(time);
      currentRef.current = false;

      setTimeout(() => {
        setSceneStatue(4);
        sellButtonRef.current.style.zIndex = 2;
        sellButtonRef.current.style.outline = "solid 4px #6fc299";
      }, 1000);
    }

    if (boughtMoney)
      setResult(
        Math.round((100000 / boughtMoney) * 25 * (nowMoney - boughtMoney))
      );
  }, [nowMoney, time]);

  const currentData = () => {
    const chartData = chartRef.current.data;

    chartData.datasets[0].data.shift();
    if (nextValueData.length === 0) {
      nextValueData.push(...NEXT_VALUE_DATA);
    }
    const newData = nextValueData[0];
    setNowMoney(newData[1]);
    const sameMoney = newData[0] === newData[1];

    nextValueData.shift();

    chartData.datasets[0].data[6] = sameMoney
      ? [newData[0], newData[1] + 0.1]
      : newData;
    chartData.datasets[0].backgroundColor.shift();
    chartData.datasets[0].backgroundColor.push(
      sameMoney ? "#6fc299" : newData[0] < newData[1] ? "#ffcdcd" : "#c1e2ff"
    );

    chartData.labels.shift();
    if (nextDateData.length === 0) {
      nextDateData.push(...NEXT_DATE_DATA);
    }
    chartData.labels[6] = nextDateData[0];
    chartData.labels.push("");
    nextDateData.shift();

    chartRef.current.update();
  };

  const buy = () => {
    clearInterval(time);
    // 赤い横線を表示
    chartRef.current.options.plugins.annotation.annotations.line1 = {
      type: "line",
      yMin: nowMoney,
      yMax: nowMoney,
      borderColor: "red",
      borderWidth: 2,
    };
    chartRef.current.update();

    setBoughtMoney(nowMoney);
    setTradeDate({ ...tradeDate, buy: chartRef.current.data.labels[6] });
    closeHint(3);
  };

  const sell = () => {
    clearInterval(time);
    setTradeDate({ ...tradeDate, sell: chartRef.current.data.labels[6] });
    sellButtonRef.current.style.zIndex = 0;
    sellButtonRef.current.style.outline = "none";
    setSceneStatue(5);
    setSumResult((sumResult) => sumResult + result);
  };

  const closeHint = (sceneStatue) => {
    setSceneStatue(null);
    if (sceneStatue === 2) {
      const intervalId = setInterval(() => {
        currentData();
      }, 2000);
      setTime(intervalId);
      // 最初のヒントクローズ5秒後に買うタイミングのヒント表示
      setBuyTime(
        setTimeout(() => {
          clearInterval(intervalId);

          setSceneStatue(3);
          buyButtonRef.current.style.zIndex = 2;
          buyButtonRef.current.style.outline = "solid 4px #6fc299";
        }, 5000)
      );
      moneyBoxRef.current.style.zIndex = 0;
    } else if (sceneStatue === 3) {
      clearTimeout(buyTime);
      setTime(
        setInterval(() => {
          currentData();
        }, 2000)
      );
      buyButtonRef.current.style.zIndex = 0;
      buyButtonRef.current.style.outline = "none";
    } else if (sceneStatue === 4) {
      setTime(
        setInterval(() => {
          currentData();
        }, 2000)
      );
      sellButtonRef.current.style.zIndex = 0;
      sellButtonRef.current.style.outline = "none";
    }
  };

  const reset = () => {
    setNowMoney(initialMoney);
    setBoughtMoney(0);
    setResult(0);
    setSceneStatue(1);
    clearInterval(time);
    nextValueData = [...NEXT_VALUE_DATA];
    currentRef.current = true;

    chartRef.current.data.datasets[0].data = [...INITIAL_VALUE_DATA];
    chartRef.current.options.plugins.annotation.annotations.line1 = null;
    chartRef.current.data.datasets[0].backgroundColor = barColor(
      INITIAL_VALUE_DATA.slice(0, 7)
    );

    chartRef.current.update();
  };

  return (
    <div className={styles["outer"]}>
      <div className={styles["container"]}>
        {sceneStatue &&
          (sceneStatue !== 5 ? (
            <div
              onClick={() => {
                if ([2, 3, 4].includes(sceneStatue)) closeHint(sceneStatue);
              }}
            >
              <BlackBg
                sceneStatue={sceneStatue}
                setSceneStatue={setSceneStatue}
                moneyBoxRef={moneyBoxRef}
              />
            </div>
          ) : (
            <Result
              result={result}
              sumResult={sumResult}
              tradeDate={tradeDate}
              onClick={reset}
            />
          ))}
        <div className={styles["game-screen"]}>
          <div className={styles["game-screen-top"]}>
            <div className={styles["chart__bg"]}>
              <Chart ref={chartRef} type="bar" data={data} options={options} />
            </div>
            <div className={styles["chart__right"]}>
              <div className={styles["now-score-label"]}>
                （ドル/円）
                <br />
              </div>
              <div className={styles["now-score-label"]}>今取引すると</div>
              <div className={styles["now-score"]}>
                {Math.round(result / 10000)}万{Math.abs(result % 10000)}円
              </div>
              <div className={styles["money-box-label"]}>今の値段</div>
              <div
                ref={moneyBoxRef}
                id="money-box"
                className={styles["money-box"]}
              >
                {nowMoney}
              </div>
            </div>
          </div>
          {!boughtMoney ? (
            <Button
              buttonRef={buyButtonRef}
              id="buy-button"
              className={styles["red__button"]}
              onClick={buy}
              label="買う"
            />
          ) : (
            <Button
              buttonRef={sellButtonRef}
              id="sell-button"
              className={styles["blue__button"]}
              onClick={sell}
              label="売る"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
